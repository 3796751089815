<script setup lang="ts">
import { isString } from '@yescapa-dev/ysc-api-js/modern'

useMetaDefaultHead()
useSeoMetaLinksAlternate()
useSeoDefault()

const route = useRoute()

const supersizingRoutes = [
  'campers-id',
]

const supersizing = ref({
  show: (isString(route.name) && supersizingRoutes.includes(route.name) && route.query.utm_source?.includes('goboony')) ?? false,
  modal: {
    show: false,
  },
})
const toggleSupersizingModal = (open: boolean) => {
  supersizing.value.modal.show = open
}
</script>

<template>
  <div>
    <YscLoadingBar />
    <LazyAppSearchSupersizingBanner
      v-if="supersizing.show"
      class="container-search"
      @close="supersizing.show = false"
      @read-more="toggleSupersizingModal(true)"
    />
    <LazyAppSupersizingAboutModal
      v-if="supersizing.modal.show"
      @close="toggleSupersizingModal(false)"
    />

    <AppHeaderContainer class="py-2.5 md:py-5">
      <template #end>
        <AppHeaderMenu show-app-link />
      </template>
    </AppHeaderContainer>

    <main>
      <slot />
    </main>

    <AppFooter />

    <ClientOnly v-if="!useFeatureFlag('hideHelp')">
      <AppHelp />
    </ClientOnly>
  </div>
</template>
